@import './var';
@import './reset';
@import './antd';


:global {
  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 240px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
  }
  .but-title {
    font-size: 18px;
    color: #0b2145;
    font-weight: 500;
    padding-left: 12px;
    position: relative;
    &:before {
      content: ' ';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-55%);
      width: 4px;
      height: 14px;
      background: #4288ff;
      border-radius: 0 4px 4px 0;
    }
  }
}

.flex-row {
  display: flex;
  justify-content: space-between;
}

.space-padding {
  padding-right: 32px !important;
  width: 260px;
  text-align: right;
}

body {
  background-color: #f6f7fb;
}
