.home {
  position: relative;
  min-height: 100vh;
  //padding-bottom: 60px;
  .logo {
    display: inline-block;
    height: 20px;
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
  }
}
