@font-face {
  font-family: "iconfont"; /* Project id 3461336 */
  src: url('iconfont.woff2?t=1660530484997') format('woff2'),
       url('iconfont.woff?t=1660530484997') format('woff'),
       url('iconfont.ttf?t=1660530484997') format('truetype'),
       url('iconfont.svg?t=1660530484997#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-zhiding:before {
  content: "\e62a";
}

.icon-a-wumulubeifen2:before {
  content: "\e63f";
}

.icon-wumulubeifen:before {
  content: "\e640";
}

.icon-a-wumulubeifen3:before {
  content: "\e641";
}

.icon-wumulu:before {
  content: "\e642";
}

.icon-shibai:before {
  content: "\e64e";
}

.icon-chenggong:before {
  content: "\e672";
}

.icon-shibai1:before {
  content: "\e650";
}

.icon-delete1:before {
  content: "\e67e";
}

.icon-download:before {
  content: "\e7f0";
}

.icon-phone:before {
  content: "\e63b";
}

.icon-bingli:before {
  content: "\e80f";
}

.icon-enlarge:before {
  content: "\e671";
}

.icon-cloud-download:before {
  content: "\e7db";
}

.icon-vertical-align-botto:before {
  content: "\e7ef";
}

.icon-check-box03-selected:before {
  content: "\e632";
}

.icon-check-box03-normal:before {
  content: "\e634";
}

.icon-phone-fill:before {
  content: "\e86d";
}

.icon-solution:before {
  content: "\e7b5";
}

.icon-calendar:before {
  content: "\e7d6";
}

.icon-check-box-normal:before {
  content: "\e61f";
}

.icon-check-box-selected:before {
  content: "\e620";
}

.icon-shouji:before {
  content: "\e600";
}

.icon-stop:before {
  content: "\e842";
}

.icon-warning-circle-fill:before {
  content: "\e848";
}

.icon-compress:before {
  content: "\e914";
}

.icon-expend:before {
  content: "\e915";
}

.icon-right:before {
  content: "\e7eb";
}

.icon-left:before {
  content: "\e7ec";
}

.icon-gengduo:before {
  content: "\e62f";
}

.icon-ellipsis:before {
  content: "\e7fc";
}

.icon-export:before {
  content: "\e791";
}

.icon-deleteteam:before {
  content: "\e7af";
}

.icon-mobile:before {
  content: "\e7b2";
}

.icon-Field-time:before {
  content: "\e910";
}

.icon-Storedprocedure:before {
  content: "\e911";
}

.icon-send:before {
  content: "\e917";
}

.icon-addteam:before {
  content: "\e7ae";
}

.icon-carryout:before {
  content: "\e7d4";
}

.icon-calendar-check:before {
  content: "\e7d5";
}

.icon-cluster:before {
  content: "\e7d7";
}

.icon-wechat-fill:before {
  content: "\e883";
}

.icon-minus-circle:before {
  content: "\e780";
}

.icon-plus-circle:before {
  content: "\e781";
}

.icon-message:before {
  content: "\e78a";
}

.icon-poweroff:before {
  content: "\e78c";
}

.icon-logout:before {
  content: "\e78d";
}

.icon-piechart:before {
  content: "\e78e";
}

.icon-setting1:before {
  content: "\e78f";
}

.icon-project:before {
  content: "\e798";
}

.icon-filedone:before {
  content: "\e7b4";
}

.icon-shop:before {
  content: "\e7ce";
}

.icon-folder-add:before {
  content: "\e7d1";
}

.icon-deploymentunit:before {
  content: "\e7d2";
}

.icon-contacts:before {
  content: "\e7d3";
}

.icon-cloud-server:before {
  content: "\e7d9";
}

.icon-cloud-upload:before {
  content: "\e7da";
}

.icon-qrcode:before {
  content: "\e7dd";
}

.icon-fund:before {
  content: "\e7de";
}

.icon-image:before {
  content: "\e7df";
}

.icon-mail:before {
  content: "\e7e0";
}

.icon-idcard:before {
  content: "\e7e1";
}

.icon-apartment:before {
  content: "\e897";
}

.icon-appstoreadd:before {
  content: "\e8eb";
}

.icon-Partition:before {
  content: "\e90e";
}

.icon-index:before {
  content: "\e90f";
}

.icon-Report:before {
  content: "\e916";
}

.icon-change-chain:before {
  content: "\e60d";
}

.icon-user:before {
  content: "\e61a";
}

.icon-list-table:before {
  content: "\e60e";
}

.icon-list-card:before {
  content: "\e60c";
}

.icon-arrow-down:before {
  content: "\e611";
}

.icon-arrow-up:before {
  content: "\e612";
}

.icon-out:before {
  content: "\e61b";
}

.icon-search:before {
  content: "\e610";
}

.icon-setting:before {
  content: "\e616";
}

.icon-nv:before {
  content: "\e617";
}

.icon-nan:before {
  content: "\e618";
}

.icon-shaixuan:before {
  content: "\e67c";
}

